<template>
	<section class="c-hero-banner-block" :class="{'c-hero-banner-block__keywords' : flag === 'keywords'}">
		<picture
				 v-if="!vm.CurrentBlock.VideoUrl && vm.BaseViewModel.DesktopImageUrl"
				 class="c-hero-banner-block__media"
				 >
			<source
					v-if="vm.BaseViewModel.MobileImageUrl"
					media="(max-width: 767px)"
					:srcset="vm.BaseViewModel.MobileImageUrl" />
			<img :src="vm.BaseViewModel.DesktopImageUrl"
				 :alt="vm.CurrentBlock.ImageAltText || vm.CurrentBlock.HeadingTextLineOne"
				 class="c-hero-banner-block__image" />
		</picture>
		<video-element
			v-else-if="vm.CurrentBlock.VideoUrl"
			:video-url="vm.CurrentBlock.VideoUrl"
			:headline="vm.CurrentBlock.HeadingTextLineTwo"
		/>
		<div class="c-hero-banner-block__content" :class="{'--keyword' : flag === 'keywords'}">
			<h2 v-if="vm.CurrentBlock.HeadingTextLineOne"
				class="c-hero-banner-block__heading-secondary"
				v-html="vm.CurrentBlock.HeadingTextLineOne">
			</h2>
			<h1 v-if="vm.CurrentBlock.HeadingTextLineTwo"
				class="c-hero-banner-block__heading-primary"
				v-html="vm.CurrentBlock.HeadingTextLineTwo">
			</h1>
			<p
			   v-if="vm.CurrentBlock.ContentTypeText"
			   v-html ="vm.CurrentBlock.ContentTypeText"
			   class="c-hero-banner-block__heading-secondary c-hero-banner-block__heading-text"
			   >
			</p>
			<ds-text
				v-if="vm.CurrentBlock.SubheadlineText"
				text-type="heading-lg"
				text-style="regular"
				class="c-hero-banner-block__subheadline"
			>
				{{ vm.CurrentBlock.SubheadlineText }}
			</ds-text>
			<div class="c-hero-banner-block__button-wrapper"
				 v-if="vm.CurrentBlock.CtaButtonLink && vm.CurrentBlock.CtaButtonText">
				<send-campaign-id-cta-button
										 :vm="vm"
										 :is-inline-layout="isInlineLayout"
										 :variant="flag === 'cta' ? 'secondary-neutral' : 'primary'"
										 />
			</div>
			<div v-if="flag === 'search'"
				 class="c-hero-banner-block__search">
				<global-search-field ref="searchField"
									 :placeholder="vm.CurrentBlock.SearchBarPlaceholder"
									 :autofocus="false"
									 flag="hero"
									 @autosuggestsearch="autoSuggestSearch"
									 @search="handleSearch"
									 @reset="cancelAutosuggestionSearch">
					<global-search-autoSuggest-dropDown-list v-if="localSearchPhrase.length"
															 flag="hero" />
				</global-search-field>
			</div>
			<div
				 v-if="vm.BaseViewModel.KeywordLinks && vm.BaseViewModel.KeywordLinks.length"
				 class="c-hero-banner-block__keywords"
				 >
				<ds-button
						   v-for="(keywordItem, index) in vm.BaseViewModel.KeywordLinks"
						   :key="index"
						   size="sm"
						   type="button"
						   variant="secondary-neutral"
						   :href="keywordItem.ContentLibraryPageUrl">
					{{ keywordItem.Keyword }}
				</ds-button>
			</div>
		</div>
	</section>
</template>

<script>
	import globalSearchAutosuggestResultsMixin from 'shared/mixins/globalSearchAutosuggestResultsMixin.js';
	import GlobalSearchField from 'shared/components/globalSearch/globalSearchField.vue';
	import GlobalSearchAutoSuggestDropDownList from 'shared/components/globalSearch/globalSearchAutosuggestDropDownList.vue';
	import VideoElement from 'shared/components/VideoElements/videoElement.vue';
	import { DsButton, DsText } from '@coloplast/design-system';
	import SendCampaignIdCtaButton from 'shared/components/actionButtonWithCTA/SendCampaignIdCtaButton.vue';

	export default {
		name: 'hero-banner-block',
		components: {
			GlobalSearchField,
			GlobalSearchAutoSuggestDropDownList,
			VideoElement,
			DsButton,
			DsText,
			SendCampaignIdCtaButton,
		},
		mixins: [globalSearchAutosuggestResultsMixin],
		props: {
			vm: Object,
			flag: String,
		},
		methods: {
			handleSearch() {
				this.executeSearch();
			},
		},
	};
</script>
